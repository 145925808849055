/* NavBar */
nav, footer {
  background-color: var(--gray-dark);
  font-size: 18px;
}
.nav-link {
  padding: 1em !important;
  color: var(--light);
}

.nav-link:hover {
  color: var(--MiColor);
}
.nav-item {
  border-bottom: solid transparent;
}
.nav-item:hover {
  border-bottom: solid var(--MiColor);
  transition: all 0.3s;
  transform: scale(1);
}

.logo {
  color: var(--MiColor);
}
.logo:hover {
  color: var(--light);
  transition: all 0.3s;
  transform: scale(1);
}
.flag {
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navbar-brand { 
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif; 
}