/* Seccion Principal */
#bordersj:before, 
#bordersj:after, 
#bordersj>:last-child:before, 
#bordersj>:last-child:after {
    border-style: solid;
    display: block;
    content: "";
    width: 100px;
    height: 100px;
    position: absolute;
}

#bordersj:before {
    border-color: var(--SJGreen); 
    top: -5px;
    left: -5px;
    border-width: 10px 0 0 10px;
}

#bordersj:after {
    border-color: var(--SJBlue); 
    top: -5px;
    right: -5px;
    border-width: 10px 10px 0 0
}

#bordersj>:last-child:before {
    border-color: var(--MiColor); 
    bottom: -5px;
    right: -5px;     
    border-width: 0 10px 10px 0
}

#bordersj>:last-child:after {
    border-color: var(--SJYellow); 
    bottom: -5px;
    left: -5px;
    border-width: 0 0 10px 10px
}

.display-1 {
    font-size: 350% !important;
}

.h3, h3 {
    font-size: 200%;
}

#header img {
    min-width: 100%;
    min-height: 100%;
    transform: scale(1.3);
    float: left;
    opacity: 0.3;
    position: absolute;
    left: 0;
    top: 0;
}

.big-icon {
    padding: 3.5em;
    line-height: 2;
}

.escale {
    transition: all .3s;
    transform: scale(1);
    position: relative;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
}

.escale:hover {
    fill: var(--light);
    color: var(--light);
    transform: scale(1.1);
    z-index: 2;
}

.icon-shape-primary {
    color: var(--light);
    background: var(--gradient);
}

.icon-shape {
    width: 4.5rem;
    height: 4.5rem;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.filter_white {
    filter: brightness(0) invert(1);
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.filter_white:hover {
    filter: brightness(1) invert(0);
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.icon {
    text-align: center;
    display: inline-flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
}
    
.background_gradient {
    background: var(--gradient);
}
.padding_sections {
    padding: 120px 0 110px;
}

.icon-card:hover {
    box-shadow: 0 20px 35px 10px rgba(255, 255, 255, 0.22);
    border-radius: 13px;
    transform: translateY(-6px);
}
.img-overlay {
    position: relative;
    transition: .5s ease;
}
.img-overlay .caption {
    transition: .5s ease;
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    text-align: center;
}

.esEvwG {
    display: block;
    /*position: absolute;*/
    width: 520px;
    /*margin-top: 5.5rem;*/
    right: 7rem;
}
.gSpnyT {
    width: 64px;
    height: 64px;
}
.hMGdqX {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    color: rgb(153, 153, 153);
    margin: 0px;
}



@media only screen and (max-width: 768px) {
    
}
@media only screen and (max-width: 1024px) {
    .esEvwG {
        position: relative;
        right: auto;
        left: auto;
        width: 100%;
        height: auto;
    }
}
@media screen and (max-width: 1260px) {

}